
$(() => {
    $('.schedule-table').each((key, val) => {
        const $val = $(val);
        const $inner = $val.find('.inner');
        const initHeight = $inner.height();
        if ($val.height() > 500) {
            $val.addClass('cover');
            $inner.height(500);
            $val.find('.show-more-button a').click((event) => {
                event.preventDefault();
                $inner.height(initHeight);
                $val.addClass('show-full');
            });
        } else {
            $val.addClass('show-full');
        }
    });
});
